<template>
  <b-container>
    <b-sidebar id="CoursesSidebar" :lazy="true" backdrop no-header>
      <b-card class="a-card-menu">
        <CourseFilter
          :filters="subjects"
          :selectedFilters="selectedSubjects"
          @update:selectedFilters="fireFilterEvent('selectedSubjects', $event)"
          :title="$t('Predmety')"
        />
        <CourseFilter
          :filters="topics"
          :showThreshold="6"
          :selectedFilters="selectedTopics"
          @update:selectedFilters="fireFilterEvent('selectedTopics', $event)"
          :title="$t('Témy')"
        />
      </b-card>
    </b-sidebar>
    <b-row>
      <b-col class="d-none d-xl-block" xl="3" md="12">
        <!-- Filters -->
        <b-card class="a-card-menu">
          <CourseFilter
            :filters="subjects"
            :selectedFilters="selectedSubjects"
            @update:selectedFilters="
              fireFilterEvent('selectedSubjects', $event)
            "
            :title="$t('Predmety')"
          />
          <CourseFilter
            :filters="topics"
            :showThreshold="6"
            :selectedFilters="selectedTopics"
            @update:selectedFilters="fireFilterEvent('selectedTopics', $event)"
            :title="$t('Témy')"
          />
        </b-card>
      </b-col>

      <b-col xl="9" md="12">
        <span
          class="cancel-filter"
          v-if="searchedCourses"
          @click="onCancelSearch"
          >{{ $t("Zrušiť vyhľadávanie") }}</span
        >
        <h1>
          {{ $t("Všetky") }}
          <span class="w-color-primary z-fancy-underline text-lowercase"
            >{{ $t("our-courses") }}<svg-fancy-underline
          /></span>
        </h1>

        <div class="sort-row">
          <b-button
            v-for="(button, index) in sortOptions"
            :key="index"
            size="sm"
            variant="secondary"
            @click="sortBy = button.value"
            :disabled="sortBy === button.value"
          >
            {{ $t(button.label) }}
          </b-button>
        </div>

        <!-- Courses tiles -->
        <Loadable :items="courses" />
        <b-row align-h="start">
          <b-col
            v-for="course of courses"
            :key="course.id"
            class="col-xxl-4 d-flex justify-content-center"
            xl="6"
            lg="4"
            md="6"
            sm="12"
          >
            <CourseCard v-if="course._model === 'course'" :course="course" />
            <PathCard v-else :path="course" />
          </b-col>
        </b-row>
        <b-row>
          <b-button
            variant="primary"
            class="mx-auto -wide a-poppins-btn -medium"
            @click="loadMore"
            v-if="showLoadMore"
            :disabled="loading"
          >
            <b-spinner small v-if="loading"></b-spinner>
            {{ $t("load-more") }}</b-button
          >
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from "/utils/axios";
import { isEmpty, set } from "lodash";
import {
  injectCourseMetaFields,
  normalizeEnumEntityResponse,
} from "/utils/helpers";
import * as Sentry from "@sentry/vue";

const PAGE_SIZE = 30;
const DEFAULT_SORT = "createdAt";

const api = {
  topics: () => {
    return axios.get("topics", {
      params: {
        populate: ["info"],
        sort: ["sortOrder", "name"],
      },
    });
  },
  subjects: () => {
    return axios.get("subjects", {
      params: {
        populate: ["info"],
        sort: ["sortOrder", "name"],
        filters: {
          registrationSubject: false,
        },
      },
    });
  },
  coursesAndPaths: async (
    filters,
    primarySort,
    loadedItems,
    auth = false,
    currentData = []
  ) => {
    const axiosMethod = auth ? "getAuth" : "get";
    const sort = [`${primarySort}:desc`, "name"];

    const populate = [
      "thumbnail",
      "subjects.info",
      "topics.info",
      "subtitleLanguages.flag",
      "badges",
    ];

    const [courses, paths] = await Promise.all([
      axios[axiosMethod](`courses`, {
        params: {
          sort,
          pagination: {
            start: loadedItems.courses,
            limit: PAGE_SIZE,
          },
          populate,
          filters: {
            ...filters,
            searchable: true,
          },
        },
      }),
      axios[axiosMethod]("paths", {
        params: {
          sort,
          filters,
          pagination: {
            start: loadedItems.paths,
            limit: PAGE_SIZE,
          },
          populate,
        },
      }),
    ]);

    courses.data.forEach((course) => injectCourseMetaFields(course, "course"));
    paths.data.forEach((path) => injectCourseMetaFields(path, "path"));

    const itemsCount = PAGE_SIZE + loadedItems.courses + loadedItems.paths;
    // We're on the last page where I allow more courses/paths on the page than CMS returned until now
    const isLastPage =
      courses.data.length + paths.data.length + currentData.length < itemsCount;

    const dataSample = courses.data[0] || paths.data[0];
    const sortCriterionIsNumber =
      typeof dataSample.attributes[primarySort] === "number";
    const sortCriterionIsDate = !isNaN(
      Date.parse(dataSample.attributes[primarySort])
    );
    const data = [...courses.data, ...paths.data, ...currentData]
      .sort((a, b) => {
        // Sort logic for number
        if (sortCriterionIsNumber) {
          if (b.attributes[primarySort] - a.attributes[primarySort] === 0) {
            return a.attributes.name.localeCompare(b.attributes.name);
          }
          return b.attributes[primarySort] - a.attributes[primarySort];
        }
        // Sort logic for dates
        if (sortCriterionIsDate) {
          if (
            new Date(b.attributes[primarySort]) -
              new Date(a.attributes[primarySort]) ===
            0
          ) {
            return a.attributes.name.localeCompare(b.attributes.name);
          }
          return (
            new Date(b.attributes[primarySort]) -
            new Date(a.attributes[primarySort])
          );
        }
        // Sort as strings otherwise
        return toString(a.attributes[primarySort]).localeCompare(
          toString(b.attributes[primarySort])
        );
      })
      .slice(0, itemsCount);

    loadedItems = { courses: 0, paths: 0 };
    data.forEach((course) => {
      if (course._model === "course") {
        loadedItems.courses += 1;
      } else {
        loadedItems.paths += 1;
      }
    });

    return { data, isLastPage, loadedItems };
  },
};

export default {
  components: {
    "svg-fancy-underline": () =>
      import("/assets/icons/fancyUnderline.svg?inline"),
    CourseFilter: () => import("/components/CourseFilter.vue"),
    PathCard: () => import("/components//Path/PathCard.vue"),
    CourseCard: () => import("/components/CourseCard.vue"),
    Loadable: () => import("/components/Loadable.vue"),
  },

  data() {
    return {
      showSidebar: false,
      topics: null,
      selectedTopics: [],
      uniqueTopics: [],
      subjects: null,
      selectedSubjects: [],
      uniqueSubjects: [],
      courses: [],
      rawCourses: null,
      searchedCourses: null,
      searchedText: null,
      filters: [],
      sortBy: DEFAULT_SORT,
      sortOptions: [
        { label: "newest", value: "createdAt" },
        { label: "most-popular", value: "popularity" },
      ],
      loadedItems: { courses: 0, paths: 0 },
      showLoadMore: true,
      loading: true,
    };
  },

  computed: {
    areCategoryFiltersSet() {
      return this.selectedTopics.length > 0 || this.selectedSubjects.length > 0;
    },
    isTextFilterSet() {
      return !!this.searchedText && this.searchedText.length > 0;
    },
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
    isFromSearchBar() {
      return !!this.searchedCourses;
    },
  },

  beforeRouteUpdate(to, from, next) {
    if (!to.query.search) this.reset();
    return next();
  },

  async mounted() {
    try {
      const [topics, subjects, coursesAndPathsWithMeta] = await Promise.all([
        api.topics(),
        api.subjects(),
        api.coursesAndPaths(
          null,
          this.sortBy,
          this.loadedItems,
          this.isLoggedIn
        ),
      ]);

      const coursesAndPaths = coursesAndPathsWithMeta.data;
      this.loadedItems = coursesAndPathsWithMeta.loadedItems;

      this.topics = normalizeEnumEntityResponse(topics);
      this.subjects = normalizeEnumEntityResponse(subjects);

      // determine whether coureses should be displayed from api or search
      this.searchedCourses = this.$route.params.searchedCourses;
      this.searchedText = this.$route.params.searchedText;

      this.showLoadMore =
        !coursesAndPathsWithMeta.isLastPage && !this.isFromSearchBar;

      this.rawCourses = coursesAndPaths;
      this.courses = this.searchedCourses || coursesAndPaths || [];

      // handle searchbar change when it changes on this screen to avoid routing hacks
      this.$events.on("courses-searched", async (results) => {
        window.history.replaceState(null, null, "?search=true");
        this.reset();
        this.searchedCourses = results.searchResults;
        this.searchedText = results.searchedText;
        this.courses = this.searchedCourses;
        this.showLoadMore = false;
        this._setDisabledFilters(false, true);
      });

      this.loading = false;
    } catch (error) {
      Sentry.captureException(error);
      this.$toast.error(error);
    }
  },

  methods: {
    async fireFilterEvent(filterType, filterSlugs) {
      this[filterType] = filterSlugs;
      const hasSelectedSubjects = !isEmpty(this.selectedSubjects);
      const hasSelectedTopics = !isEmpty(this.selectedTopics);
      const hasSearchedText = Boolean(this.searchedText);

      this.uniqueSubjects = new Set();
      this.uniqueTopics = new Set();

      if (
        (hasSelectedSubjects && hasSelectedTopics) ||
        (hasSearchedText && (hasSelectedTopics || hasSelectedSubjects))
      ) {
        this.$eventLogger.log("course.filter.combined", {
          topics: this.selectedTopics.join(","),
          subjects: this.selectedSubjects.join(","),
          searched_text: this.searchedText,
        });
      } else {
        if (filterSlugs.length) {
          this.$eventLogger.log("course.filter", {
            filter_value: `${filterType}: ${filterSlugs.join(",")}`,
          });
        }
      }

      if (!hasSelectedSubjects && !hasSelectedTopics && !this.isFromSearchBar) {
        this._setDisabledFilters(true);
        this.courses = this.rawCourses;
      }

      if (hasSelectedSubjects) {
        this.selectedSubjects.forEach((subject) =>
          this.uniqueSubjects.add(subject)
        );
      }

      if (hasSelectedTopics) {
        this.selectedTopics.forEach((topic) => this.uniqueTopics.add(topic));
      }

      this.loadedItems = { courses: 0, paths: 0 };
      await this._fetchCourses();

      this.courses.forEach((course) => {
        course.attributes.subjects.data.forEach((s) =>
          this.uniqueSubjects.add(s.attributes.info.slug)
        );
        course.attributes.topics.data.forEach((t) =>
          this.uniqueTopics.add(t.attributes.info.slug)
        );
      });

      // disable/enable filter according to current selection
      this._setDisabledFilters();
    },

    onCancelSearch() {
      const url = new URL(window.location);
      url.searchParams.delete("search");
      window.history.replaceState(null, null, url);
      this.reset();
    },

    reset() {
      this.courses = this.rawCourses;
      this.searchedCourses = null;
      this.searchedText = "";
      this.loadedItems = { courses: 0, paths: 0 };
      this.filters = [];
      this.selectedTopics = [];
      this.selectedSubjects = [];
      this.sortBy = DEFAULT_SORT;
      this.showLoadMore = true;
      this.loading = false;
      this._setDisabledFilters(true);
    },

    async loadMore() {
      await this._fetchCourses();
    },

    async _fetchCourses() {
      const currentData =
        this.loadedItems.courses || this.loadedItems.paths ? this.courses : [];
      this.loading = true;

      const courseFilters = {
        $and: [
          !isEmpty(this.selectedSubjects)
            ? set(
                {},
                "subjects.info.slug.$in",
                !isEmpty(this.selectedSubjects) ? this.selectedSubjects : [""]
              )
            : {},
          !isEmpty(this.selectedTopics)
            ? set(
                {},
                "topics.info.slug.$in",
                !isEmpty(this.selectedTopics) ? this.selectedTopics : [""]
              )
            : {},
        ],
      };
      const coursesWithMeta = await api.coursesAndPaths(
        courseFilters,
        this.sortBy,
        this.loadedItems,
        this.isLoggedIn,
        currentData
      );
      this.loadedItems = coursesWithMeta.loadedItems;
      this.courses = coursesWithMeta.data;
      this.loading = false;

      this.showLoadMore = !coursesWithMeta.isLastPage;
    },

    _setDisabledFilters(emptyFilters = false, allDisabled = false) {
      if (!this.subjects || !this.topics) return;

      this.subjects.forEach((subject) => {
        if (allDisabled) return (subject.disabled = true);
        const isNotSelected =
          this.selectedSubjects.indexOf(subject.slug) === -1;
        const isNotAvailable =
          (!!this.selectedTopics.length || this.isFromSearchBar) &&
          !this.uniqueSubjects.has(subject.slug);
        // const isNotAvailable = !this.uniqueSubjects.has(subject.slug)
        subject.disabled = !emptyFilters && isNotAvailable && isNotSelected;
      });

      this.topics.forEach((topic) => {
        if (allDisabled) return (topic.disabled = true);
        const isNotSelected = this.selectedTopics.indexOf(topic.slug) === -1;
        const isNotAvailable =
          (!!this.selectedSubjects.length || this.isFromSearchBar) &&
          !this.uniqueTopics.has(topic.slug);
        // const isNotAvailable = !this.uniqueTopics.has(topic.slug)
        topic.disabled = !emptyFilters && isNotAvailable && isNotSelected;
      });

      // reactivity fix -> fix some other way?
      this.subjects.splice(this.subjects.length);
      this.topics.splice(this.topics.length);
    },
  },
  watch: {
    async sortBy() {
      this.$eventLogger.log("course.sort", {
        sort_value: this.sortBy,
      });
      this.loadedItems = { courses: 0, paths: 0 };
      if (!this.isFromSearchBar) await this._fetchCourses();
    },
  },
};
</script>
<style lang="scss" scoped>
.cancel-filter {
  font-size: 0.9375rem;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 0.625rem;
  &:hover {
    color: #4784ff;
  }
}
.sort-row {
  display: flex;
  margin: 1.75rem 0 3rem 0;
  gap: 0.75rem;
  button {
    padding: 0.5rem 2rem;
    color: var(--a-color-secondary);
    background-color: rgba(var(--a-color-secondary-rgb), $alpha: 0.1);
    border: none;
    &:disabled {
      color: var(--a-color-white);
      background-color: var(--a-color-secondary);
      opacity: initial;
    }
  }
}
</style>
